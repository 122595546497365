import React from 'react'
import Project from '../Components/Project'
import GDB from '../Images/GameDevelopmentBasics.png'
import GBDC from '../Images/GameDevelopmentBootcamp.png'
import IGP from '../Images/GameProduction.png'
import TDM from '../Images/3DModeling.png'
import Afterlight from '../Images/Afterlight.png'
import FNF from '../Images/FnF.png'
import GV from '../Images/GalacticVeins.png'

const projects = [
  { name: 'Galactic Veins (Demo Launching April 2025)',
    desc: 'Embark on an epic journey where the cosmos is your battleground, and resources are the key to galactic supremacy. Galactic Veins is a space survival game where you must mine resources, build ships, and conquer planets to survive. The game crosses multiple universes while implementing elements from realtime strategy games, survival games, and a rich story that unfolds as you explore the galaxy.',
    href: 'https://www.galacticveins.com/',
    image: GV},
  { name: 'Game Development Basics', 
    desc: 'This is an eight-week course that will teach you the basics of game development using Unreal Engine Blueprints. We start with the absolute basics and slowly build your knowledge of game development. During this course, I will guide you in the creation of four template projects that are an excellent start to your portfolio.',  
    href: 'https://www.youtube.com/watch?v=PwieSLo7SjA&list=PLF_ue_ea-VTrhbJQ4R61n3KjbAGkOjH_N', 
    image: GDB },
  { name: 'Game Development Bootcamp', 
    desc: 'This is an eight-week crash course in Unreal Engine Blueprints and C++. We will cover the basics of game development by using the Unreal Engine template projects as a starting point. This course is designed to give you a solid foundation in game development and prepare you for a career in the industry.', 
    href: 'https://www.youtube.com/playlist?list=PLF_ue_ea-VTop8fHohpJZGI3u9LNmM4HC', 
    image: GBDC },
  { name: 'Introduction to Game Production',
    desc: 'This is a ten-part course that will teach you the basics of game production. We will cover the entire game development process, from concept to launch. This course is designed for beginners who want to learn how to create games from start to finish.',
    href: 'https://www.youtube.com/playlist?list=PLF_ue_ea-VTovl3QcXrtnnzwUARnP_CY2',
    image: IGP },
  { name: '3D Modeling for Game Development (March 2025)',
    desc: 'This course will teach you the basics of 3D modeling for game development. We will cover the fundamentals of 3D modeling, texturing, and animation. This course is designed for beginners who want to learn how to create 3D models for games.',
    href: 'https://www.youtube.com/playlist?list=PLF_ue_ea-VToWMxycreHR1AaCLSj0PsGB',
    image: TDM },
  { name: 'Afterlight',
    desc: 'Afterlight is a 3D cooperative exploration game where players must work together to find Runes and complete the Reconnect ritual. This game was created for the 2022 Global Game Jam, and it won an award for Best Peaceful Game.',
    href: 'https://jimdublace.itch.io/afterlight',
    image: Afterlight },
  { name: 'Fireflies and Figments',
    desc: 'Fireflies & Figments: A Willow’s Journey is a 3rd person, top-down, adventure game where you take control of both characters in a single player co-op experience as you solve puzzles, earn badges for new skills, and explore a mysterious forest where your imagination brings fear and wonder to life!',
    href: 'https://store.steampowered.com/app/1853650/Fireflies__Figments_A_Willows_Journey/',
    image: FNF },
]

function Projects() {
  return (
    <div>
      <div className='Header'>Projects</div>
      <div className='flex flex-col flex-wrap justify-center'>
        {projects.map((project) => (
          <Project
            key={project.name}
            Title={project.name}
            Description={project.desc}
            Image={project.image}
            url={project.href}
          />  
        ))}
        </div>
    </div>
    
  )
}

export default Projects